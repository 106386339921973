import React, { useState } from 'react';
import Layout from '../layouts/layout';
import './blog.css'
import { FaAngleRight } from "react-icons/fa";
import { Link } from 'gatsby';
import BlogCategory from '../components/blog-category';
import favicon from "../images/favico.ico";
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';




export default function BlogPage(props) {
    const [postsPerPage] = useState(6);


    const { data } = props.pageContext

    const postNumber = [];
    const posts = [];
    if (data) {
        console.log("toooooooooooooototal",data.length)

        for (let index = 1; index <= Math.ceil(data.length / postsPerPage); index++) {
            postNumber.push(index);
        }


        for (let index = 0; index <= 5; index++) {
            posts.push(data[index]);
        }
    }
    console.log('pooooooooosts',posts.length)




    return (

        <Layout>
            <Helmet htmlAttributes={{
                lang: 'fr-FR',
            }}>
                <link rel="icon" type="image/x-icon" href={favicon} />
                <title>medica-tour.fr/blog/</title>
                <meta name="robots" content="index, follow" />
                <meta name="description" content="Découvrez des articles et des conseils de qualité sur le tourisme médical et la santé à l'étranger sur le blog de Medica-Tour.Planifiez votre voyage en toute confiance " />

                <link data-react-helmet="true" rel="canonical" href={"https://www.medica-tour.fr/blog/"}></link>
                <meta name="msvalidate.01" content="BF6180D2B9843FD051D8456A095B9C38" />
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10925153812"></script>
    <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-10925153812');
      `}
    </script>
            </Helmet>

            <div className="container-fluid service pt-3 pb-5">
                <div className="container intern-page-style pt-5">
                    <h1 className="service-title">
                        Blog
                    </h1>

                </div>
            </div>


            <div className="container mb-5 pt-5">
                <div className="row">
                    <div className="col-sm-12 col-lg-8 blogs">
                        <div class="row">
                            {posts && posts.map((item) => {
                                var today = new Date(item.date);
                                var dd = String(today.getDate()).padStart(2, '0');
                                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                                var yyyy = today.getFullYear();
                                return (

                                    <div class="col-12 col-md-6 col-xl-4 mb-4">
                                        <div class="card mr-3">
                                            <Link className="btn " aria-current="page"
                                                to={item.uri}>
                                                <img style={{ height: '190px', width: '300px' }} src={item.featuredImage.sourceUrl} className="img-fluid rounded" alt={item.title} />
                                            </Link>
                                            <div class="card-body">
                                                <span class="card-text">
                                                    {dd + '/' + mm + '/' + yyyy}
                                                </span><br />
                                                <Link className="text-decoration-none" aria-current="page"
                                                    to={item.uri}>
                                                    <span class="card-title blog-title">{parse(item.title)}</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </div >
                    <div className="col-sm-12 col-lg-4 ">
                        <BlogCategory />
                    </div>
                    
                </div >
            </div >

            <div className="container-fluid mt-5 mb-5 pb-5">
                <nav>
                    <ul className="pagination justify-content-center">

                        {postNumber.map((item) => {

                            if (item === 1) {
                                return <li key={item} className="active" >
                                    <Link style={{ color: 'black', fontWeight: 'bold' }} className="page-link activeLi" to={'/blog/'}>{item}</Link>
                                </li>
                            } else {
                                return <li key={item} >
                                    <Link style={{ fontWeight: 'bold' }} className="page-link" to={'/blog/' + item + '/'}>{item}</Link>
                                </li>
                            }
                        })

                        }
                        <li className="page-item ">
                            <Link style={{ fontWeight: 'bold' }} className="page-link" to={'/blog/2/'}><FaAngleRight /></Link>
                        </li>
                    </ul>
                </nav>
            </div>


        </Layout >
    )
}